<template lang="pug">
div
  van-grid(:column-num='2' square)
    van-grid-item.dp-grid(
      v-for="(item, index) of dataArr"
      :key="index"
      :to="item.to"
      )
      template(#default)
        van-image(:src="item.url + previewSize" width="38vw" height="38vw" radius='8')
        span.van-grid-item__text(:title='item.name') 
          span {{item.code}}{{ item.name }}
  loading-view(:value="loadingState")

</template>

<script>
import { previewSize } from '../../lib/helpers/OssImageHelper'

export default {
  data() {
    return {
      previewSize,
      loadingState: false,
      dataArr: []
    }
  },
 
  methods: {
    async init() {
      this.loadingState = true
      let params = {
        where: {
          com_id_poi_companys: this.$store.state.routeInfo.comId,
          state: 1
        },
        order: '-order'
      }
      const res = await this.$api.GetColorCard(params)
      this.dataArr = res.data.items.map(p => {
        return {
          ...p,
          to: {
            name: 'colordetail',
            query: {
              id: p.id
            }
          }
        }
      })
      this.loadingState = false
    }
  },
  created() {
    this.init()
  },
}
</script>

<style lang="less" scoped>
.dp-grid {
  .van-grid-item__content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .van-grid-item__text {
    width: 100%;
    margin:auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align:center;
    vertical-align: middle;
  }
}
</style>

